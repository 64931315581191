import React from 'react';
import { Helmet } from 'react-helmet';
import SoftPhone from './components/SoftPhone';

function App() {
  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      </Helmet>
      <div className="container">
        <SoftPhone userExtension="201" />
      </div>
    </>
  );
}

export default App;

import { useState, useEffect, useRef, useCallback } from 'react';
import * as SIP from 'sip.js';
import config from '../config';
import queryString from 'query-string';

export const SoftPhoneLogic = () => {
    const [userExtension, setUserExtension] = useState('201');
    const [status, setStatus] = useState('Not Registered');
    const [dialNumber, setDialNumber] = useState('2409888024');
    const [showAnswerReject, setShowAnswerReject] = useState(false);
    const [isCallInitiated, setIsCallInitiated] = useState(false);
    const [isCallActive, setIsCallActive] = useState(false);
    const [isCallOnHold, setIsCallOnHold] = useState(false);
    const [ringTone, setRingTone] = useState(null);

    const userAgentRef = useRef(null);
    const sessionRef = useRef(null);
    const remoteAudioRef = useRef(null);

    useEffect(() => {
        const parsed = queryString.parse(window.location.search);
        if (parsed.ext) {
            setUserExtension(parsed.ext);
        }
    }, []);

    useEffect(() => {
        const audio = new Audio('/sounds/elise.mp3');
        audio.loop = true;
        setRingTone(audio);
        return () => {
            audio.pause();
            audio.currentTime = 0;
        };
    }, []);

    const handleIncomingCall = useCallback((invitation) => {
        const callerId = invitation.request.from.uri.user;
        setStatus('Incoming call from ' + callerId);
        setShowAnswerReject(true);
        sessionRef.current = invitation;

        playRingTone();

        invitation.stateChange.addListener((newState) => {
            switch (newState) {
                case SIP.SessionState.Establishing:
                    setStatus('Call Connecting');
                    setShowAnswerReject(false);
                    setIsCallActive(true);
                    setIsCallInitiated(false);
                    stopRingTone();
                    break;
                case SIP.SessionState.Established:
                    setStatus('Call Connected');
                    setShowAnswerReject(false);
                    setIsCallActive(true);
                    setIsCallInitiated(false);
                    stopRingTone();
                    break;
                case SIP.SessionState.Terminated:
                    setStatus('Call Ended');
                    setShowAnswerReject(false);
                    setIsCallActive(false);
                    setIsCallInitiated(false);
                    sessionRef.current = null;
                    stopRingTone();
                    break;
                default:
                    setStatus(`Call state: ${newState}`);
                    break;
            }
        });
    }, [ringTone]);

    useEffect(() => {
        const userUri = SIP.UserAgent.makeURI(`sip:${userExtension}@${config.sipDomain}`);
        const userAgent = new SIP.UserAgent({
            uri: userUri,
            transportOptions: { server: config.sipServer },
            authorizationUsername: userExtension,
            authorizationPassword: config.authorizationPassword,
        });

        userAgentRef.current = userAgent;

        userAgent.delegate = {
            onInvite: handleIncomingCall,
            onConnect: () => setStatus('Ready'),
            onDisconnect: () => setStatus('Disconnected'),
            onRegistered: () => setStatus('Registered'),
            onUnregistered: () => setStatus('Unregistered'),
            onRegistrationFailed: () => setStatus('Registration Failed'),
        };

        userAgent.start().then(() => {
            const registerer = new SIP.Registerer(userAgent);
            registerer.register();
        }).catch((error) => {
            console.error("Error starting the UserAgent: ", error);
            setStatus('Failed to start UserAgent');
        });

        return () => {
            userAgent.stop();
        };
    }, [userExtension, handleIncomingCall]);


    const makeCall = () => {
        if (!dialNumber) {
            alert("Please enter a number to call");
            return;
        }

        const formattedNumber = dialNumber.length === 10 ? '1' + dialNumber : dialNumber;
        const targetURI = SIP.UserAgent.makeURI(`sip:${formattedNumber}@${config.sipDomain}`);

        setIsCallInitiated(true);

        const inviter = new SIP.Inviter(userAgentRef.current, targetURI, {
            sessionDescriptionHandlerOptions: {
                constraints: { audio: true, video: false }
            }
        });

        sessionRef.current = inviter;

        inviter.stateChange.addListener((newState) => {
            switch (newState) {
                case SIP.SessionState.Establishing:
                    setStatus('Ringing...');
                    setIsCallInitiated(true);
                    playRingTone();
                    break;
                case SIP.SessionState.Established:
                    setStatus('Call connected');
                    attachRemoteAudio(inviter);
                    setIsCallActive(true);
                    setIsCallInitiated(false);
                    stopRingTone();
                    break;
                case SIP.SessionState.Terminated:
                    setStatus('Call Terminated');
                    sessionRef.current = null;
                    setIsCallActive(false);
                    setIsCallInitiated(false);
                    stopRingTone();
                    break;
                default:
                    setStatus('Call state: ' + newState);
                    setIsCallInitiated(false);
                    break;
            }
        });

        inviter.invite().catch((error) => {
            console.error("Error making call:", error);
            setStatus('Call Failed');
            setIsCallInitiated(false);
        });
    };

    const hangUp = () => {
        if (sessionRef.current) {
            const currentState = sessionRef.current.state;
            try {
                switch (currentState) {
                    case SIP.SessionState.Initial:
                    case SIP.SessionState.Establishing:
                        if (sessionRef.current instanceof SIP.Inviter) {
                            sessionRef.current.cancel();
                        } else {
                            sessionRef.current.reject();
                        }
                        break;
                    case SIP.SessionState.Established:
                        sessionRef.current.bye();
                        break;
                    default:
                        // Handle any unspecified states
                        break;
                }
            } catch (error) {
                console.error('Error while hanging up:', error);
            }

            setStatus('Call Ended');
            setIsCallActive(false);
            stopRingTone();
        }
        else {
            setStatus('No active call to hang up');
        }
        sessionRef.current = null;
    };

    const answerCall = async () => {
        if (!(sessionRef.current instanceof SIP.Invitation)) return;

        try {
            await sessionRef.current.accept({
                sessionDescriptionHandlerOptions: {
                    constraints: { audio: true, video: false }
                }
            });
            setStatus('Call Answered');
            attachRemoteAudio(sessionRef.current);
            setIsCallActive(true);
            stopRingTone();
        } catch (error) {
            setStatus('Call Unable to Answer');
            console.error("Error receiving call:", error);
        } finally {
            setShowAnswerReject(false);
        }
    };

    const rejectCall = () => {
        if (sessionRef.current instanceof SIP.Invitation) {
            sessionRef.current.reject();
            setStatus('Call Rejected');
            setShowAnswerReject(false);
            sessionRef.current = null;
            stopRingTone();
        }
    };

    const attachRemoteAudio = (session) => {
        const remoteStream = new MediaStream();
        session.sessionDescriptionHandler.peerConnection.getReceivers().forEach(receiver => {
            if (receiver.track.kind === 'audio') {
                remoteStream.addTrack(receiver.track);
            }
        });
        remoteAudioRef.current.srcObject = remoteStream;
    };

    const holdCall = async (session) => {
        if (!session || session.state !== SIP.SessionState.Established) {
            console.error('Cannot hold call: session is not established');
            return;
        }

        try {
            // Send a re-INVITE with the hold option
            await session.invite({
                requestDelegate: {
                    onAccept: () => {
                        console.log('Call is now on hold');
                    },
                    onReject: () => {
                        console.error('Failed to put call on hold');
                    }
                },
                sessionDescriptionHandlerOptions: {
                    hold: true // Indicate that the call should be put on hold
                }
            });
        } catch (error) {
            console.error('Error putting call on hold:', error);
        }
    };

    const unholdCall = async (session) => {
        if (!session || session.state !== SIP.SessionState.Established) {
            console.error('Cannot unhold call: session is not established');
            return;
        }

        try {
            // Send a re-INVITE to resume the call
            await session.invite({
                requestDelegate: {
                    onAccept: () => {
                        console.log('Call is now resumed');
                    },
                    onReject: () => {
                        console.error('Failed to resume call');
                    }
                },
                sessionDescriptionHandlerOptions: {
                    hold: false // Indicate that the call should be resumed
                }
            });
        } catch (error) {
            console.error('Error resuming call:', error);
        }
    };

    const toggleHold = useCallback(() => {
        if (sessionRef.current) {
            if (isCallOnHold) {
                unholdCall(sessionRef.current);
                setIsCallOnHold(false);
                setStatus('Call resumed');
            } else {
                if (sessionRef.current.state === SIP.SessionState.Established) {
                    holdCall(sessionRef.current);
                    setIsCallOnHold(true);
                    setStatus('Call on hold');
                }
                else {
                    setStatus(`Call state: ${sessionRef.current.state}`);
                }
            }
        }
    }, [isCallOnHold, sessionRef, setStatus]);

    const stopRingTone = () => {
        if (ringTone) {
            ringTone.pause();
            ringTone.currentTime = 0;
        }
    };

    const playRingTone = () => {
        if (ringTone) {
            ringTone.play().catch(e => console.error("Error playing ringtone:", e));
        }
    };

    const forwardCall = async () => {
        if (!sessionRef.current || sessionRef.current.state !== SIP.SessionState.Established) {
            console.error('Cannot forward call: no active call to forward');
            return;
        }

        const forwardNumber = prompt("Enter the extension to forward the call to (e.g. 202):");
        if (forwardNumber) {
            const targetURI = SIP.UserAgent.makeURI(`sip:${forwardNumber}@${config.sipDomain}`);

            try {
                console.log(`Attempting to forward call to ${forwardNumber}`);
                sessionRef.current.refer(targetURI, {
                    requestDelegate: {
                        onAccept: () => {
                            console.log(`Call successfully forwarded to ${forwardNumber}`);
                            setStatus(`Call forwarded to ${forwardNumber}`);
                            setIsCallActive(false);
                            // Optionally clear the session here if you are sure the forward was successful
                            sessionRef.current = null;
                        },
                        onReject: (response) => {
                            console.error("Forwarding rejected:", response.message.reasonPhrase);
                            setStatus('Call Forward Failed');
                            // Decide whether to keep the session active or not
                        }
                    }
                });

            } catch (error) {
                console.error("Error forwarding call:", error);
                setStatus('Call Forward Failed');
                // Decide whether to keep the session active or not
            }
        }
    };

    return {
        userExtension,
        status,
        dialNumber,
        setDialNumber,
        showAnswerReject,
        isCallActive,
        isCallInitiated,
        remoteAudioRef,
        makeCall,
        hangUp,
        answerCall,
        rejectCall,
        isCallOnHold,
        toggleHold,
        forwardCall // Include forwardCall in the returned object
    };
};